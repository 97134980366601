import { ProfileField } from '../state';
import { Field, FieldValue } from './data';
import * as widgets from './widgets';

export default function dynamicField(f: ProfileField) {
  let field: Field = {
    name: f.id,
    label: f.label,
    isRequired: f.isRequired,
    validate: (v: FieldValue) => [],
    widget: widget(f),
    section: f.section,
  };
  return field;
}

function widget(f: ProfileField) {
  if (f.type === 'select') {
    let values = f.values.split('\n');
    return widgets.SelectWidget(values);
  } else if (f.type === 'date') {
    return widgets.DateWidget;
  } else if (f.type === 'phone') {
    return widgets.PhoneWidget();
  } else {
    return widgets.Input('text');
  }
}
