import React from 'react';
import TextField from '@material-ui/core/TextField';
import { WidgetProps } from './data';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MuiPhoneNumber from 'material-ui-phone-number';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

type InputType = 'text' | 'password' | 'email';

export function Input(type: InputType) {
  return function InputText(p: WidgetProps) {
    let hasErrors = p.errors.length > 0;
    return (
      <TextField
        fullWidth
        type={type}
        key={p.field.name}
        id={p.field.name}
        name={p.field.name}
        label={p.field.label}
        value={p.value}
        onChange={(e) => p.onChange(e.target.value)}
        error={hasErrors}
        helperText={hasErrors ? p.errors.join(' ') : p.field.helpText}
      />
    );
  };
}

export function SelectWidget(options: string[]) {
  return function SelectW(p: WidgetProps) {
    let hasErrors = p.errors.length > 0;
    return (
      <React.Fragment key={p.field.name}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-age-native-simple">
            {p.field.label}
          </InputLabel>
          <Select
            native
            value={p.value}
            onChange={(e) => p.onChange((e as any).target.value)}
            label={p.field.label}
            error={hasErrors}
            inputProps={{
              name: p.field.name,
              id: 'outlined-age-native-simple',
            }}
          >
            <option aria-label="None" value="" />
            {options.map((o) => (
              <option key={o} value={o}>
                {o}
              </option>
            ))}
          </Select>
        </FormControl>
        {hasErrors && <p className="error">{p.errors.join(' ')}</p>}
      </React.Fragment>
    );
  };
}

export function DateWidget(p: WidgetProps) {
  let hasErrors = p.errors.length > 0;
  let value: Date | null = p.value ? new Date(p.value) : null;
  return (
    <React.Fragment key={p.field.name}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          id={p.field.name}
          label={`${p.field.label} (DD/MM/YYYY)`}
          format="dd/MM/yyyy"
          inputVariant="outlined"
          value={value}
          onChange={(value) => {
            if (value && isNaN(value.getTime())) {
              p.onChange('');
            } else {
              p.onChange(value?.toUTCString() || '');
            }
          }}
          KeyboardButtonProps={{
            'aria-label': `Change ${p.field.name} date`,
          }}
        />
      </MuiPickersUtilsProvider>
      {hasErrors && <p className="error">{p.errors.join(' ')}</p>}
    </React.Fragment>
  );
}

export function PhoneWidget(options: void) {
  return function PhoneW(p: WidgetProps) {
    let hasErrors = p.errors.length > 0;
    /*
          preferredCountries={['ae']}
          defaultCountry="ae"
          placeholder="+971 (050) 1234567"
          */
    return (
      <React.Fragment key={p.field.name}>
        <MuiPhoneNumber
          id={p.field.name}
          name={p.field.name}
          label={p.field.label}
          value={p.value}
          onChange={(e) => p.onChange((e as any) as string)}
        />
        {hasErrors && <p className="error">{p.errors.join(' ')}</p>}
      </React.Fragment>
    );
  };
}

export function Terms(p: WidgetProps) {
  let hasErrors = p.errors.length > 0;
  return (
    <div className="terms" key={p.field.name}>
      <p>
        I have read and understood{' '}
        <a href="/terms/" target="_blank">
          terms and conditions
        </a>{' '}
        and accept the contents in their entirety.
      </p>
      <FormControlLabel
        control={
          <Checkbox
            checked={!!p.value}
            onChange={(e) => p.onChange(e.target.checked ? 'checked' : '')}
            name={p.field.name}
          />
        }
        label="Accept the Terms & Conditions"
      />
      {hasErrors && <p className="error">{p.errors.join(' ')}</p>}
    </div>
  );
}
