import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import './pwa.js';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
