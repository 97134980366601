import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios';
import { Draft } from 'immer';
import { Store } from 'pullstate';

import { shallowEqual } from './shallowEqualObject';
import { findLast } from './findLast';

let LOADER_ID = 0;

interface Cache {
  createdAt: number;
  params: Params;
  data: any;
}

let CACHE: Map<number, Cache[]> = new Map();

type Params = { [key: string]: string };

/* Return null if path is invalid, otherwise an array of all params */
function parsePathParams(path: string): null | string[] {
  let openedAt: number | null = null;
  let params: string[] = [];
  for (let i = 0; i < path.length; i++) {
    if (path[i] === '{') {
      if (openedAt != null) return null;
      openedAt = i;
    } else if (path[i] === '}') {
      if (openedAt != null) {
        params.push(path.substring(openedAt + 1, i));
      } else {
        return null;
      }
      openedAt = null;
    }
  }
  if (openedAt != null) return null;
  return params;
}

export interface Data<D> {
  data: D | null;
  prevData: D | null;
  params: Params;
  error: null | {
    code: string;
    message: string;
  };
  pagination?: { totalCount: number };
  _id: number;
  _cancelSrc: null | CancelTokenSource;
}

export function newData<D>(): Data<D> {
  return {
    data: null,
    prevData: null,
    params: {},
    error: null,
    _id: 0,
    _cancelSrc: null,
  };
}

interface Loader<S extends object, D, ApiD> {
  url: string;
  store: Store<S>;
  prepare: (api_data: ApiD) => D;
  pagination?: (api_data: ApiD) => { totalCount: number };
  get: (s: S) => Data<D>;
  set: (s: Draft<S>, data: Data<D>) => void;
  cacheTimeMillis?: number;
  axiosConfig?: Partial<AxiosRequestConfig>;
}

type LoaderFn = (params: Params) => void;

export function loader<S extends object, D, ApiD>(
  conf: Loader<S, D, ApiD>
): LoaderFn {
  let loaderId = LOADER_ID;
  LOADER_ID++;

  let pathParams = parsePathParams(conf.url);
  if (pathParams == null)
    throw new Error('Invalid path params in URL ' + conf.url);

  return async (params: Params) => {
    // each request made with this loader will get a unique id
    // when the request finished, if the id differes it means another
    // request was fired in the meantime
    let id: number | null = null;
    let cancelSrc = axios.CancelToken.source();
    let axiosConfig = conf.axiosConfig || {};

    // check cache
    if (conf.cacheTimeMillis) {
      let cache = CACHE.get(loaderId) || [];
      let now = +new Date();
      cache = cache.filter(
        (c) => now - c.createdAt < (conf.cacheTimeMillis || 0)
      );
      CACHE.set(loaderId, cache);

      let c = findLast(cache, (c) => shallowEqual(c.params, params));

      if (c != null) {
        let data = c.data as D;
        conf.store.update((draft, state) => {
          let d = newData<D>();
          let prev = conf.get(state);
          if (prev._cancelSrc) {
            prev._cancelSrc.cancel('new_request');
          }
          id = prev._id + 1;
          d.data = data;
          conf.set(draft, d);
        });
        return; // short-circuit
      }
    }

    // prepare state before starting a request
    conf.store.update((draft, state) => {
      let d = newData<D>();
      let prev = conf.get(state);
      if (prev._cancelSrc) {
        prev._cancelSrc.cancel('new_request');
      }
      id = prev._id + 1;
      // If multiple requests are fired then prev.data will be null
      d.prevData = prev.data || prev.prevData;
      d.params = params;
      d._id = id;
      d._cancelSrc = cancelSrc;
      conf.set(draft, d);
    });

    // start request
    try {
      // prepare params
      let url = conf.url;
      let queryParams = { ...params };
      if (pathParams)
        pathParams.forEach((p) => {
          delete queryParams[p];
          url = url.replace(`{${p}}`, params[p] || '');
        });

      let response = await axios.get(url, {
        ...axiosConfig,
        params: queryParams,
        cancelToken: cancelSrc.token,
      });

      let d = newData<D>();
      d.data = conf.prepare(response.data as ApiD);
      if (conf.pagination) {
        d.pagination = conf.pagination(response.data);
      }

      // Cache data
      if (conf.cacheTimeMillis) {
        let cache = CACHE.get(loaderId) || [];
        cache.push({
          params,
          createdAt: +new Date(),
          data: d.data,
        });
        CACHE.set(loaderId, cache);
      }

      d.params = params;
      conf.store.update((draft, state) => {
        if (conf.get(state)._id === id) {
          conf.set(draft, d);
        } else {
          console.log('Stale request ', id);
        }
      });
    } catch (e) {
      if (axios.isCancel(e)) {
        return;
      }
      let d = newData<D>();
      d.params = params;
      conf.store.update((draft, state) => {
        if (conf.get(state)._id === id) {
          conf.set(draft, d);
        }
      });
    }
  };
}

export { Store };
