import styled from 'styled-components';
import { Avatar } from '@material-ui/core';
import RichText from '../components/RichText';
import { RouteComponentProps } from 'react-router-dom';
import { AppStore, useTitle } from '../state';

interface PathParams {
  id: string;
}

export default function MemberDetails(props: RouteComponentProps<PathParams>) {
  const site = AppStore.useState((s) => s.main?.site);
  let title = site?.membersTitlePlural || '';
  useTitle(title, '/app/members');
  let members = AppStore.useState((s) => s.main?.members) || [];
  let id = parseInt(props.match.params.id, 10);
  let member = members.find((m) => m.userId === id);

  if (member == null) return null;

  let bgStyle = member.headerImage
    ? { backgroundImage: `url('${member.headerImage}')` }
    : {};

  return (
    <Wrap>
      <div className="background-container" style={bgStyle}>
        <Avatar alt={`${member.firstName}'s image`} src={member.image} />
      </div>
      <h2>
        {member.firstName} {member.lastName}
      </h2>

      <div className="body">
        {member.memberSince && (
          <h3>
            Member since: <i>{member.memberSince}</i>
          </h3>
        )}
        <h3>{site?.membersInfoTitle || 'About me'}</h3>
        <RichText html={member.biography} />
        <h3>{site?.membersContactTitle || 'Contact details'}</h3>
        {member.profile && (
          <p>
            Contact: <i>{member.profile?.contact || '-'}</i>
          </p>
        )}
      </div>
    </Wrap>
  );
}

let Wrap = styled.div`
  padding-bottom: 45px;

  .background-container {
    width: 100%;
    padding-top: 50%; /* trick to create a desired aspect-ratio */
    background: url('${require('../imgs/home-header.jpg').default}') 50% 100%;
    background-size: cover;
    position: relative;
    margin-bottom: 85px;
  }

  .gradeLevel {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-variant: smallcaps;
    margin: 5px 0 20px 0;
  }

  .gradeLevel > span:first-child {
    font-size: 1em;
    height: 1.3em;
    margin-right: 8px;
  }

  .MuiRating-root {
    font-size: 1.3em;
    display: flex;
    justify-content: center;
  }

  .MuiAvatar-root {
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 120px;
    width: 120px;
    border: 6px solid #444;
    box-shadow: inset 0px 0px 0px 4px #444;
    border-radius: 50%;
    transform: translate(-50%, 50%);
  }

  .MuiAvatar-root img {
    border-radius: 50%;
  }

  .MuiRating-root {
    font-size: 1.5em;
    display: flex;
    justify-content: center;
  }

  h2 {
    text-align: center;
    line-height: 1.3em;
    text-transform: uppercase;
  }

  .body {
    border-top: 2px solid #bbb;
    padding: 20px 20px;
    padding-bottom: 20px;

    font-size: 0.9em;

    h3 {
      font-size: 1.2em;
      margin: 15px 0 10px 0;
      font-weight: bold;

      i {
        font-weight: normal;
        font-style: normal;
      }
    }
  }
`;
