import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';

const validationSchema1 = yup.object({
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  passwordAgain: yup.string(),
});

const RegForm = () => {
  let [globalError, setGlobalError] = React.useState<string | null>(null);
  let [passwordChanged, setPasswordChanged] = React.useState<boolean>(false);

  const formik1 = useFormik({
    initialValues: {
      password: '',
      passwordAgain: '',
    },
    validationSchema: validationSchema1,
    onSubmit: async (data) => {
      if (data.password !== data.passwordAgain) {
        formik1.setFieldError('passwordAgain', 'Password did not match');
        return;
      }

      try {
        await axios.post('/api/change_password/', data);
        setPasswordChanged(true);
      } catch (e) {
        if (e.response && e.response.data) {
          setGlobalError('An error happened.');
          for (const [key, value] of Object.entries(e.response.data)) {
            if (key && Array.isArray(value)) {
              [formik1].forEach((f: any) => {
                if (f.values[key] != null) {
                  f.setFieldError(key, value.join(' '));
                }
              });
            }
          }
        } else {
          setGlobalError('An error happened. Please try again or contact us.');
        }
      }
    },
  });

  if (passwordChanged) {
    return <h3>You password was changed</h3>;
  }

  return (
    <>
      <form onSubmit={formik1.handleSubmit}>
        {globalError && <p className="error">{globalError}</p>}
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          value={formik1.values.password}
          onChange={formik1.handleChange}
          error={formik1.touched.password && Boolean(formik1.errors.password)}
          helperText={formik1.touched.password && formik1.errors.password}
        />

        <TextField
          fullWidth
          id="password_again"
          name="passwordAgain"
          label="Password again"
          type="password"
          value={formik1.values.passwordAgain}
          onChange={formik1.handleChange}
          error={
            formik1.touched.passwordAgain &&
            Boolean(formik1.errors.passwordAgain)
          }
          helperText={
            formik1.touched.passwordAgain && formik1.errors.passwordAgain
          }
        />

        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={formik1.isSubmitting}
        >
          {formik1.isSubmitting ? <CircularProgress size={20} /> : 'Save'}
        </Button>
      </form>
    </>
  );
};

export default function Register() {
  return (
    <Root>
      <RegForm />
    </Root>
  );
}

let Root = styled.div`
  form {
    padding: 0;
  }

  form > * {
    margin: 8px 0;
    width: 100%;
  }

  h1 {
    flex: 1;
    font-size: 1.2em;
    text-align: center;
    text-transform: uppercase;
  }

  .error {
    color: #f44336;
    font-size: 0.75rem;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
`;
