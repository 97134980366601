let VERSION = window.APP_VERSION;

interface Data<T> {
  version: string;
  data: T;
}

export function get<T>(key: string): T | null {
  let d = localStorage.getItem(key);
  if (d) {
    let data = JSON.parse(d) as Data<T>;
    if (data.version !== VERSION) return null;
    return data.data;
  }
  return null;
}

export function set<T>(key: string, data: T) {
  localStorage.setItem(
    key,
    JSON.stringify({ version: VERSION, time: +new Date(), data: data })
  );
}
