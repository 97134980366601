import React from 'react';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import { AppStore, useTitle, Member, refreshMainData } from '../state';

export default function MemberList() {
  let title = AppStore.useState((s) => s.main?.site?.membersTitlePlural || '');
  useTitle(title, '/app');
  let [search, setSearch] = React.useState<string>('');
  let members = AppStore.useState((s) => s.main?.members || []);
  let regex = new RegExp(
    search
      .toLowerCase()
      .split(/\W+/)
      .map((m) => m.replace(/[^\w]/g, ''))
      .join('|')
  );

  return (
    <Wrap>
      <Search value={search} onChange={setSearch} />
      <ul>
        {members
          .filter((m) => m.searchTerm.match(regex) != null)
          .map((m) => (
            <MemberItem key={m.userId} {...m} />
          ))}
      </ul>
    </Wrap>
  );
}

const Wrap = styled.div`
  ul {
    padding: 0;
    margin: 0;
  }
`;

interface SearchProps {
  value: string;
  onChange: (val: string) => any;
}

function Search(props: SearchProps) {
  let [focus, setFocus] = React.useState<boolean>(false);

  return (
    <SearchWrap>
      <div className={focus ? 'focused' : undefined}>
        <SearchIcon />
        <input
          value={props.value}
          placeholder="Search"
          onChange={(e) => props.onChange(e.target.value)}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </div>
    </SearchWrap>
  );
}

const SearchWrap = styled.div`
  background: var(--main-color);
  padding: 5px 10px 10px 10px;
  color: #aaa;

  input {
    border: none;
    background: none;
    height: 40px;
    color: #333;
    outline: none;
  }

  & > div {
    background: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    transition: background 0.5ms ease-in-out;
  }

  & > div.focused {
    background: #eee;
  }
`;

function MemberItem(member: Member) {
  return (
    <MemberLi>
      <Link to={`/app/member/${member.userId}`}>
        <Avatar alt={`${member.firstName}'s image`} src={member.image} />
        {member.firstName} {member.lastName}
      </Link>
    </MemberLi>
  );
}

let MemberLi = styled.li`
  a {
    list-style: none;
    padding: 10px 10px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
  }

  .MuiAvatar-root {
    margin-right: 10px;
  }
`;
