import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AppBar, Toolbar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AppStore, loadProfileFields, refreshMainData } from '../state';
import { useForm, Form, FieldValue, dynamicField } from '../form';
import * as widgets from '../form/widgets';
import { Errors, Field } from '../form/data';

const RegForm = (props: { fields: Field[] }) => {
  let fields = props.fields;

  let history = useHistory();

  let form = useForm({
    validate: (f: Form) => {
      if (f.state.values['password'] !== f.state.values['password_again']) {
        return ["Passwords don't match"];
      }
      return [];
    },
    onSubmit: async (f: Form) => {
      f.state.setLoading(true);
      try {
        let data = f.state.values;
        await axios.post('/api/register/', data);
        await refreshMainData();
        history.push('/app/');
      } catch (e: any) {
        if (e.response && e.response.data) {
          let errors: Errors = {};
          for (const [key, value] of Object.entries(e.response.data)) {
            if (key && Array.isArray(value)) {
              errors[key] = value;
            }
          }
          f.state.setErrors(errors);
        }
      } finally {
        f.state.setLoading(false);
      }
    },
    fields: [
      {
        name: 'first_name',
        label: 'First name',
        isRequired: true,
        widget: widgets.Input('text'),
        section: 0,
      },
      {
        name: 'last_name',
        label: 'Last name',
        isRequired: true,
        widget: widgets.Input('text'),
        section: 0,
      },
      {
        name: 'email',
        label: 'E-mail',
        isRequired: true,
        widget: widgets.Input('email'),
        validate: (v: FieldValue) => {
          if (/^\S+@\S+$/.test(v)) {
            return [];
          } else {
            return ['Enter a valid e-mail'];
          }
        },
        section: 0,
      },
      {
        name: 'password',
        label: 'Password',
        isRequired: true,
        widget: widgets.Input('password'),
        section: 0,
      },
      {
        name: 'password_again',
        label: 'Password again',
        isRequired: true,
        widget: widgets.Input('password'),
        section: 0,
      },

      ...fields,
      {
        name: 'terms',
        label: 'Accept the Terms & Conditions',
        isRequired: true,
        widget: widgets.Terms,
        section: fields[fields.length - 1]?.section || 0,
      },
    ],
  });

  return (
    <>
      <form>
        {form.elems().map((e) => e.render())}

        {form.state.globalErrors.length > 0 && (
          <p className="error">{form.state.globalErrors.join(' ')}</p>
        )}
        {form.state.section > 0 && (
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="button"
            disabled={form.state.loading}
            onClick={form.prevSection}
          >
            Back
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="button"
          disabled={form.state.loading}
          onClick={form.onSubmit}
        >
          {form.state.loading ? (
            <CircularProgress size={20} />
          ) : form.isLastSection() ? (
            'Register'
          ) : (
            'Next'
          )}
        </Button>
      </form>
      {form.state.section === 0 && (
        <p style={{ padding: '20px' }}>
          Already have an account yet? <Link to="/app/">Log in here.</Link>
        </p>
      )}
    </>
  );
};

export default function Register() {
  React.useEffect(() => {
    loadProfileFields({});
  }, []);

  const fields = AppStore.useState((s) => s.profileFields);

  return (
    <Root>
      <AppBar position="static" style={{ background: 'var(--main-color)' }}>
        <Toolbar>
          <h1>Register</h1>
        </Toolbar>
      </AppBar>
      {fields.data && <RegForm fields={fields.data.map(dynamicField)} />}
    </Root>
  );
}

let Root = styled.div`
  .terms {
    color: #444;
  }

  form {
    padding: 20px 20px;
  }

  form > * {
    margin: 8px 0;
    width: 100%;
  }

  h1 {
    flex: 1;
    font-size: 1.2em;
    text-align: center;
    text-transform: uppercase;
  }

  .error {
    color: #f44336;
    font-size: 0.75rem;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
`;
