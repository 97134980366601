import React from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import * as pages from './pages';
import AppBar from './components/AppBar';
import { AppStore, refreshMainData } from './state';
import './App.css';

function App() {
  let location = useLocation();

  let isRegister = useRouteMatch('/app/register');
  let isResetPassword = useRouteMatch('/app/reset_password');
  isRegister = isRegister || isResetPassword;

  let [auth, loading, promptRefresh, isVerified] = AppStore.useState((s) => [
    s.auth,
    s.loading,
    s.promptRefresh,
    s.main?.me.isVerified || false,
  ]);

  React.useEffect(() => {
    refreshMainData();
  }, [location]);

  if (loading) return null;

  if (!auth && !isRegister) {
    return <pages.Login />;
  }

  if (!isVerified && !isRegister) {
    return (
      <Message>
        <AppBar />
        <p>Please wait until your profile is verified.</p>
      </Message>
    );
  }

  return (
    <div>
      {!isRegister && <AppBar />}

      {promptRefresh && (
        <PromptRefresh onClick={promptRefresh}>
          The app has been updated.
          <br />
          Please click here to reload.
        </PromptRefresh>
      )}

      <Switch>
        <Route
          exact
          path="/app/book/:categoryId/:year/:month"
          component={pages.EventList}
        />
        <Route exact path="/app/event/:id" component={pages.EventDetails} />
        <Route exact path="/app/member/:id" component={pages.MemberDetails} />
        <Route exact path="/app/members" component={pages.MemberList} />
        <Route exact path="/app/about" component={pages.About} />
        <Route exact path="/app/settings" component={pages.Settings} />
        <Route exact path="/app/register" component={pages.Register} />
        <Route
          exact
          path="/app/reset_password"
          component={pages.ResetPassword}
        />
        <Route exact path="/app/" component={pages.Home} />
      </Switch>
    </div>
  );
}

let PromptRefresh = styled.div`
  position: fixed;
  top: 80px;
  text-align: center;
  padding: 10px;
  line-height: 1.2;
  border-radius: 10px;
  width: 250px;
  left: 50%;
  transform: translate(-50%);
  height: auto;
  z-index: 300;
  background: var(--main-color);
  color: white;
  opacity: 0.95;
  cursor: pointer;
`;

let Message = styled.div`
  display: flex;
  flex-direction: column;

  p {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    padding: 20px;
  }
`;

export default App;
